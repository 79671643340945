import React, { ChangeEventHandler, ReactElement } from 'react';

export interface IListPagerConfigProps {
    perPageOptions: number[];
    label: string;
    value: number;
    prefix?: string;
    onChange?: (value: number) => void;
}

const PerPageSelect = ({ label, perPageOptions, value, prefix, onChange }: IListPagerConfigProps): ReactElement => {
    const htmlId = (prefix ?? '') + 'perPage';
    const handleChange: ChangeEventHandler<HTMLSelectElement> = event => {
        if (onChange === undefined) {
            return;
        }
        onChange(parseInt(event.target.value));
    };
    if (perPageOptions.length <= 1) {
        return <></>;
    }
    return (
        <div className={'form__cell'}>
            <label className={'form__label'} htmlFor={htmlId}>
                {label}
            </label>
            <div className={'custom-select'}>
                <select id={htmlId} onChange={handleChange} defaultValue={value}>
                    {perPageOptions.map(option => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default PerPageSelect;

import { BasicObject } from '~components/ObjectHelper';

const encodeQueryData = (data: BasicObject): string => {
    const ret = [];
    for (const dataKey in data) {
        if (Array.isArray(data[dataKey])) {
            for (const itemKey in data[dataKey]) {
                ret.push(encodeURIComponent(dataKey) + '[]' + '=' + encodeURIComponent(data[dataKey][itemKey]));
            }
            continue;
        }
        ret.push(encodeURIComponent(dataKey) + '=' + encodeURIComponent(data[dataKey]));
    }
    return ret.join('&');
};

const changeWindowUrl = (url: string): void => {
    window.history.pushState('', '', url);
};

const addToUrl = (params: BasicObject): void => {
    const newParams = getJSONDataFromLocationSearch('');

    Object.entries(params).map(([name, value]) => (newParams[name] = value));

    changeWindowUrl('?' + encodeQueryData(newParams) + location.hash);
};

const getJSONDataFromLocationSearch = (prefix: string): BasicObject => {
    const filteredData: BasicObject = {};
    const search = location.search.substring(1);

    if ('' === search) {
        return filteredData;
    }

    const data = JSON.parse(
        '{"' +
            decodeURI(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}',
    );

    if (undefined === data) {
        return filteredData;
    }

    Object.entries(data).map(([name, value]) => {
        if (0 === name.indexOf(prefix)) {
            filteredData[name.replace(prefix, '')] = value;
        }
    });

    return filteredData;
};

export { encodeQueryData, changeWindowUrl, getJSONDataFromLocationSearch, addToUrl };

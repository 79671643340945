import { render } from 'react-dom';
import { getJsonDataFromDataAttribute } from '~utils/json';
import FilterList from '../react/components/FilterList';
import React from 'react';
import { FilterListConfigInterface } from '~types/FilterListConfigInterface';

const InitFilterList = (): void => {
    const elements: Element[] = [...document.querySelectorAll('.js_filterList')];

    if (0 === elements.length) {
        return;
    }

    elements.map(element => {
        // TODO add type check on received config
        render(
            <FilterList
                config={getJsonDataFromDataAttribute(element.getAttribute('data-config')) as FilterListConfigInterface}
            />,
            element,
        );
    });
};

export default InitFilterList;

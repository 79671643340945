import React from 'react';
import Icon from '~react/components/FilterList/components/Icon';
import { confirmAlert } from 'react-confirm-alert';
import { BasicObject } from '~components/ObjectHelper';
import { ajaxHandler, AjaxHandlerResponse, AjaxHandlerResponseError } from '~components/AjaxHandler';
import { encodeQueryData } from '~utils/url';
import { FilterListFilterInterface } from '~types/FilterListFilterInterface';

interface ActionButtonProps {
    action: {
        label: string;
        url: string;
        addSelected: boolean;
        addFilter: boolean;
        confirm: string | null | undefined;
        method: 'get' | 'put' | 'delete' | 'post' | undefined;
        icon: string;
        modal: boolean | undefined;
    };
    filter?: FilterListFilterInterface;
    postData: BasicObject;
    buttonType: 'link' | 'primary' | 'secondary' | 'icon-link';
    disabled: boolean;
    handleSuccess: (response: AjaxHandlerResponse) => void;
    handleError: (response: AjaxHandlerResponseError) => void;
    openModal: (url: string) => void;
    beforePost?: () => void;
    texts: {
        confirm: {
            label: string;
            yes: string;
            no: string;
        };
    };
}

const ActionButton: React.FC<ActionButtonProps> = props => {
    const process = () => {
        switch (props.action.method) {
            case undefined:
            case 'get':
                if (undefined !== props.action.modal && props.action.modal) {
                    if (props.action.addSelected) {
                        props.openModal(props.action.url + '?' + encodeQueryData(props.postData));
                        break;
                    }
                    props.openModal(props.action.url);
                    break;
                }
                if (props.action.addFilter && undefined !== props.filter) {
                    location.href = props.action.url + '?' + encodeQueryData(props.filter);
                    break;
                }
                location.href = props.action.url;

                break;
            case 'delete':
                if (undefined !== props.beforePost) {
                    props.beforePost();
                }
                ajaxHandler.deleteRequest(
                    props.action.url,
                    props.postData,
                    response => props.handleSuccess(response),
                    response => props.handleError(response),
                );
                break;
            case 'put':
                if (undefined !== props.beforePost) {
                    props.beforePost();
                }
                ajaxHandler.put(
                    props.action.url,
                    props.postData,
                    response => props.handleSuccess(response),
                    response => props.handleError(response),
                );
                break;
            case 'post':
                if (undefined !== props.beforePost) {
                    props.beforePost();
                }
                ajaxHandler.post(
                    props.action.url,
                    props.postData,
                    response => props.handleSuccess(response),
                    response => props.handleError(response),
                );
                break;
        }
    };

    const onClickHandler = () => {
        if (null === props.action.confirm || undefined === props.action.confirm) {
            process();
            return;
        }

        confirmAlert({
            title: '' !== props.action.confirm ? props.action.confirm : props.texts.confirm.label,
            message: '',
            buttons: [
                {
                    label: props.texts.confirm.yes,
                    onClick: () => process(),
                },
                {
                    label: props.texts.confirm.no,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <button
            className={'icon-link' === props.buttonType ? 'btn -icon-link' : 'btn -with-icon -' + props.buttonType}
            onClick={onClickHandler}
            disabled={props.disabled}
            title={'icon-link' === props.buttonType ? props.action.label : undefined}
        >
            <Icon type={props.action.icon} />
            <span className="label">{props.action.label}</span>
        </button>
    );
};

export default ActionButton;

import { FilterListConfigInterface } from '~types/FilterListConfigInterface';
import { FilterListFilterInterface } from '~types/FilterListFilterInterface';
import Item, { RowInterface } from '~react/components/FilterList/utils/Item';
import { useEffect, useState } from 'react';
import { ajaxHandler, AjaxHandlerResponse, AjaxHandlerResponseError } from '~components/AjaxHandler';
import EventHelper from '~components/EventHelper';
import { addToUrl, getJSONDataFromLocationSearch } from '~utils/url';
import { BasicObject } from '~components/ObjectHelper';

const defaultConfig: FilterListConfigInterface = {
    apiUrl: '',
    selected: undefined,
    columns: [],
    listActions: [],
    translations: {
        empty: '',
        check: '',
        checkAll: '',
        asc: '',
        desc: '',
        confirm: {
            label: '',
            yes: '',
            no: '',
        },
    },
    filters: [],
    prefix: '',
    pager: null,
};

const defaultFilter: FilterListFilterInterface = {
    page: 1,
    perPage: false,
    orderType: false,
    orderBy: false,
};

export interface FilterListState {
    config: {
        data: FilterListConfigInterface;
        isLoaded: boolean;
    };
    items: {
        data: RowInterface[];
        footer: RowInterface | null;
        isLoaded: boolean;
        hasNext: boolean;
    };
    modalURL: string;
    selected: string[];
    reload: boolean;
}

interface FilterListMessage {
    type: 'error' | 'success';
    message: string;
}

interface FilterListHook {
    state: FilterListState;
    filter: FilterListFilterInterface;
    message: FilterListMessage;
    setMessage: (reponse: AjaxHandlerResponse | AjaxHandlerResponseError, type: 'error' | 'success') => void;
    setItemsIsLoaded: (value: boolean) => void;
    setPage: (page: number) => void;
    setPerPage: (perPage: number) => void;
    setSort: (by: string, type: string) => void;
    loadItemsData: () => void;
    toggleSelect: (id: string) => void;
    isItemSelected: (items: RowInterface[]) => boolean;
    getItemId: (item: RowInterface) => string;
    onModalClose: (reload?: boolean) => void;
    onModalOpen: (url: string) => void;
    onFilterInputChange: (name: string, value: string, reload: boolean) => void;
}

export default function useFilterList(config: FilterListConfigInterface): FilterListHook {
    const [queryAbortController, setQueryAbortController] = useState<AbortController>(new AbortController());
    const [state, setState] = useState<FilterListState>({
        config: {
            data: defaultConfig,
            isLoaded: false,
        },
        items: {
            data: [],
            footer: null,
            isLoaded: false,
            hasNext: false,
        },
        modalURL: '',
        selected: [],
        reload: false,
    });

    const [filter, setFilter] = useState<FilterListFilterInterface>({
        ...defaultFilter,
        ...{ perPage: config.pager?.perPage ?? false },
    });

    const [message, setStateMessage] = useState<FilterListMessage>({
        type: 'success',
        message: '',
    });

    const setMessage = (reponse: AjaxHandlerResponse | AjaxHandlerResponseError, type: 'error' | 'success') => {
        if (reponse.type !== 'error' && reponse.type !== 'message') {
            setStateMessage({
                type,
                message: '',
            });
            return;
        }
        setStateMessage({
            type,
            message: reponse.message,
        });
    };

    const setItemsIsLoaded = (value: boolean) => {
        setState({
            ...state,
            ...{
                items: { ...state.items, ...{ isLoaded: value } },
            },
        });
    };

    const setReload = (value: boolean) => {
        setState({
            ...state,
            ...{
                reload: value,
            },
        });
    };

    const setSelected = (value: string[]) => {
        setState({
            ...state,
            ...{
                selected: value,
            },
        });
    };

    const setPage = (page: number) => {
        setFilter({ ...filter, ...{ page: page } });
    };

    const setPerPage = (perPage: number) => {
        setFilter({ ...filter, ...{ perPage, page: 1 } });
    };

    const setSort = (by: string, type: string) => {
        setFilter({ ...filter, ...{ orderBy: by, orderType: type } });
    };

    const setFilterByName = (name: string, value: string) => {
        const newFilter = { ...filter };

        newFilter[name] = value;
        newFilter['page'] = 1;

        setFilter({
            ...filter,
            ...newFilter,
        });
    };

    const setModalURL = (modalURL: string) => {
        setState({
            ...state,
            ...{
                modalURL: modalURL,
            },
        });
    };

    const addFilterParamsToURL = () => {
        const params: { [key: string]: string | boolean | number } = {};
        Object.entries(filter).map(([name, value]) => (params[state.config.data.prefix + name] = value));
        addToUrl(params);
    };

    const loadItemsData = () => {
        setItemsIsLoaded(false);
        queryAbortController.abort();
        const abortController = new AbortController();
        setQueryAbortController(abortController);
        ajaxHandler.get(
            state.config.data.apiUrl,
            {
                params: filter,
                signal: abortController.signal,
            },
            response => {
                if (response.type !== 'gridData') {
                    console.warn(`Expected "gridData" response, but got "${response.type}"`, { response });
                    setState({
                        ...state,
                        items: {
                            data: [],
                            footer: null,
                            hasNext: false,
                            isLoaded: true,
                        },
                    });
                    return;
                }

                setState({
                    ...state,
                    items: {
                        data: response.rows,
                        footer: response.footer,
                        hasNext: response.hasNext,
                        isLoaded: true,
                    },
                });
            },
            error => {
                if (error.canceled) {
                    console.info('Data fetching cancelled', error);
                    return;
                }
                setMessage(error, 'error');
                setState({
                    ...state,
                    items: {
                        data: [],
                        footer: null,
                        hasNext: false,
                        isLoaded: true,
                    },
                });

                if (error.reload) {
                    window.location.reload();
                }
            },
        );
    };

    useEffect(() => {
        setState({
            ...state,
            ...{
                config: {
                    data: { ...state.config.data, ...config },
                    isLoaded: true,
                },
            },
        });

        let filterData: BasicObject = {};

        if (undefined !== config.filters) {
            config.filters.map(cell => {
                filterData[cell.name] = cell.defaultValue;
            });
        }

        filterData = {
            ...filterData,
            ...getJSONDataFromLocationSearch(config.prefix),
        };

        setFilter({
            ...filter,
            ...filterData,
        });
    }, []);

    useEffect(() => {
        if (!state.config.isLoaded) {
            return;
        }
        loadItemsData();
        EventHelper.dispatch('filterListChangeHeight');
    }, [state.config.isLoaded]);

    useEffect(() => {
        EventHelper.dispatch('filterListChangeHeight');

        if (0 === state.items.data.length) {
            return;
        }

        const modalLinks: Element[] = [
            ...document.querySelectorAll(`.${state.config.data.prefix}filterList [data-filterlist-modal]`),
        ];

        modalLinks.map(modalLink => {
            modalLink.addEventListener('click', event => {
                event.preventDefault();
                const linkURL = modalLink.getAttribute('href');
                if (null === linkURL) {
                    return;
                }
                setModalURL(linkURL);
            });
        });
    }, [state.items.data]);

    useEffect(() => {
        addFilterParamsToURL();
        if (!state.config.isLoaded) {
            return;
        }
        if (state.reload) {
            location.reload();
            return;
        }
        setSelected([]);
        loadItemsData();
    }, [filter]);

    const onFilterInputChange = (name: string, value: string, reload: boolean) => {
        setReload(reload);
        setFilterByName(name, value);
    };

    const toggleSelect = (id: string) => {
        setState(Item.toggleSelected(id, state));
    };

    const onModalClose = (reload?: boolean) => {
        setModalURL('');
        if (true === reload) {
            state.modalURL = '';
            loadItemsData();
        }
    };

    const onModalOpen = (url: string) => {
        setModalURL(url);
    };

    const isItemSelected = (items: RowInterface[]) => {
        return Item.isSelected(
            items,
            state.selected,
            undefined === state.config.data.selected ? 'id' : state.config.data.selected,
        );
    };

    const getItemId = (item: RowInterface) => {
        return Item.getValue(
            item,
            undefined === state.config.data.selected ? 'id' : state.config.data.selected,
        ).toString();
    };

    return {
        state,
        filter,
        message,
        setMessage,
        setItemsIsLoaded,
        setPage,
        setPerPage,
        setSort,
        loadItemsData,
        toggleSelect,
        isItemSelected,
        getItemId,
        onModalClose,
        onModalOpen,
        onFilterInputChange,
    };
}

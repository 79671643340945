const filterFormInit = (): void => {
    const formElements: Element[] = [...document.querySelectorAll('.js_FilterForm')];

    if (0 === formElements.length) {
        return;
    }

    formElements.forEach(form => {
        const submitElements: Element[] = [...document.querySelectorAll('[data-submit-on-change]')];

        submitElements.forEach(submitElement => {
            submitElement.addEventListener('change', () => {
                (form as HTMLFormElement).submit();
            });
        });
    });
};

export default filterFormInit;

import '../styles/components/tabs.scss';
import EventHelper from '~components/EventHelper';
import { changeWindowUrl } from '~utils/url';

class Tabs {
    private openClass = '-active';
    private openContentClass = '-active';

    public handleTab = (toggleElement: Element, type: 'open' | 'close'): void => {
        const targetId: null | string = toggleElement.getAttribute('data-tabs-toggle');

        if (null === targetId || ('open' === type && toggleElement.classList.contains(this.openClass))) {
            return;
        }

        const toggleElements: Element[] = [...document.querySelectorAll(`[data-tabs-toggle="${targetId}"]`)];

        toggleElements.forEach(tElement => {
            if ('open' === type) {
                tElement.classList.add(this.openClass);
            } else {
                tElement.classList.remove(this.openClass);
            }
        });

        const targetContentElement: null | HTMLElement = document.getElementById(targetId);

        if (null === targetContentElement || null === targetContentElement.firstElementChild) {
            return;
        }

        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if ('open' === type) {
            if (768 > width) {
                targetContentElement.style.maxHeight =
                    (targetContentElement.firstElementChild as HTMLElement).offsetHeight + 'px';
            }
            targetContentElement.classList.add(this.openContentClass);
        } else {
            targetContentElement.classList.remove(this.openContentClass);
            targetContentElement.style.maxHeight = '';
        }
    };

    public setMaxHeight = (): void => {
        const openContentElements: Element[] = [...document.querySelectorAll(`.js_tabContent.${this.openClass}`)];

        openContentElements.map(openContentElement => {
            if (null === openContentElement.firstElementChild) {
                return;
            }

            (openContentElement as HTMLElement).style.maxHeight =
                (openContentElement.firstElementChild as HTMLElement).offsetHeight + 'px';
        });
    };

    public toggleTab = (toggleElement: Element): void => {
        const groupId: null | string = toggleElement.getAttribute('data-tabs-group');
        const isOpen: boolean = toggleElement.classList.contains(this.openClass);
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (null === groupId) {
            return;
        }

        const groupTabsElements: Element[] = [...document.querySelectorAll(`[data-tabs-group="${groupId}"]`)];

        groupTabsElements.forEach(groupTabsElement => {
            this.handleTab(groupTabsElement, 'close');
        });

        if (768 > width && isOpen) {
            this.handleTab(toggleElement, 'close');
        } else {
            this.handleTab(toggleElement, 'open');
        }

        // css transition time
        setTimeout(() => {
            EventHelper.dispatch('tabTransitionEnd');
        }, 500);
    };

    public checkTabs = (groups: string[]): void => {
        groups.map(group => {
            const openGroupsElements: Element[] = [
                ...document.querySelectorAll(`[data-tabs-group="${group}"].${this.openClass}`),
            ];

            if (0 !== openGroupsElements.length) {
                return;
            }

            const elementToOpen: null | HTMLElement = document.querySelector(`[data-tabs-group="${group}"]`);

            if (null === elementToOpen) {
                return;
            }

            this.handleTab(elementToOpen, 'open');
        });
    };

    public initTabs = (): void => {
        const toggleElements: Element[] = [...document.querySelectorAll('.js_tabToggle')];
        const groups: string[] = [];

        if (0 === toggleElements.length) {
            return;
        }

        toggleElements.forEach(toggleElement => {
            const group = toggleElement.getAttribute('data-tabs-group');

            if (null !== group && !groups.includes(group)) {
                groups.push(group);
            }

            toggleElement.addEventListener('click', event => {
                event.preventDefault();
                this.toggleTab(toggleElement);
                const url = toggleElement.getAttribute('href');
                if (null === url) {
                    return;
                }
                changeWindowUrl(url);
            });
        });

        this.setMaxHeight();

        document.addEventListener('filterListChangeHeight', () => {
            this.setMaxHeight();
        });

        window.addEventListener('resize', () => {
            this.checkTabs(groups);
            this.setMaxHeight();
        });

        const hash = location.hash;

        const toggleButton = document.querySelector(`[href="${hash}"]`);

        if (null === toggleButton || !(toggleButton as HTMLElement).classList.contains('js_tabToggle')) {
            return;
        }

        (toggleButton as HTMLAnchorElement).click();
    };
}

export default Tabs;

import { render } from 'react-dom';
import MultipleFileInput from '../react/atoms/MultipleFileInput';
import React from 'react';
import { getJsonDataFromDataAttribute } from '~utils/json';

const initMultipleFileInput = (): void => {
    const elements: Element[] = [...document.querySelectorAll('.js_multipleFileInput')];

    if (0 === elements.length) {
        return;
    }

    elements.map(element => {
        // TODO add type check on received texts
        render(
            <MultipleFileInput
                texts={
                    getJsonDataFromDataAttribute(element.getAttribute('data-texts')) as {
                        delete: string;
                        addNew: string;
                        note: string;
                    }
                }
                accept={element.getAttribute('data-accept')}
                required={element.hasAttribute('data-required')}
            />,
            element,
        );
    });
};

export default initMultipleFileInput;

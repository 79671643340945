import { FilterListState } from '~react/components/FilterList/state';
import { calculateContrastBwColor } from '~utils/color';
import { CSSProperties } from 'react';
import { z } from 'zod';

const cellSchema = z.object({
    value: z.union([z.string(), z.number()]),
    classes: z.nullable(z.string()),
    backgroundColor: z.nullable(z.string()),
    rowSpan: z.number(),
    colSpan: z.number(),
});

type CellInterface = z.infer<typeof cellSchema>;

const actionSchema = z.object({
    label: z.string(),
    url: z.string(),
    method: z.enum(['get', 'post', 'put', 'delete']),
    postData: z.record(z.any()),
    icon: z.string(),
    confirm: z.nullable(z.string()),
    modal: z.boolean(),
});

const actionsCellSchema = z.object({
    actions: z.array(actionSchema),
    rowSpan: z.number(),
});

export const rowSchema = z.object({
    class: z.string(),
    cells: z.record(z.nullable(cellSchema)),
    actionsCell: z.nullable(actionsCellSchema),
});

type RowInterface = z.infer<typeof rowSchema>;

class Item {
    private static getCell(item: RowInterface, column: string): CellInterface | null | undefined {
        return item.cells[column];
    }

    static getValue(item: RowInterface, column: string): string | number {
        const cell = this.getCell(item, column);

        return cell?.value ?? '';
    }

    static isCellSkipped(item: RowInterface, name: string): boolean {
        const cell = this.getCell(item, name);

        return 'undefined' !== typeof cell && cell === null;
    }

    static getCellStyle(item: RowInterface, name: string): CSSProperties | undefined {
        const cell = this.getCell(item, name);

        return cell?.backgroundColor
            ? {
                  backgroundColor: cell?.backgroundColor,
                  color: calculateContrastBwColor(cell?.backgroundColor),
              }
            : undefined;
    }

    static getCellRowSpan(item: RowInterface, name: string): number {
        const cell = this.getCell(item, name);

        return cell?.rowSpan ?? 1;
    }

    static getClasses(item: RowInterface, column: string): string {
        const cell = this.getCell(item, column);

        return cell?.classes ?? '';
    }

    static toggleAll(state: FilterListState): FilterListState {
        const newSelected: string[] = [];

        if (state.selected.length !== state.items.data.length) {
            state.items.data.map(item => {
                newSelected.push(
                    this.getValue(
                        item,
                        undefined === state.config.data.selected ? 'id' : state.config.data.selected,
                    ).toString(),
                );
            });
        }

        return {
            ...state,
            ...{
                selected: newSelected,
            },
        };
    }

    static toggleSelected(id: string, state: FilterListState): FilterListState {
        if ('checkAll' === id) {
            return this.toggleAll(state);
        }
        let newSelectedItems: string[] = [...state.selected];

        if (newSelectedItems.includes(id)) {
            newSelectedItems = newSelectedItems.filter(selectValue => {
                return id !== selectValue;
            });
        } else {
            newSelectedItems.push(id);
        }

        return {
            ...state,
            ...{
                selected: newSelectedItems,
            },
        };
    }

    static isSelected(items: RowInterface[], selectedItems: string[], column: string): boolean {
        let isSelected = true;

        if (0 === items.length) {
            isSelected = false;
        }

        items.forEach(item => {
            const value = Item.getValue(item, column);
            if (!selectedItems.includes(value.toString())) {
                isSelected = false;
                return;
            }
        });

        return isSelected;
    }
}

export default Item;
export { RowInterface };
